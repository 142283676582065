import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {useEffect, useState} from "react";
import {editProduct, getProductId} from "../../../../../utils/axios/api/ProductInterface";
import {ProductResponseInterface} from "../../../../../utils/axios/api/ProductInterface";
import {addProductTranslation} from "../../../components/ProductAdmin";
import {Button, Row} from "antd";
import GeneralPanel from "./components/GeneralPanel";
import PricePanel from "./components/PricePanel";
import {ProductInterface} from "../../../../../utils/axios/api/ProductInterface";


const Product = () => {
  const { t } = useTranslation();
  const params = useParams();
    const [productInfo:ProductResponseInterface, setProductInfo] = useState(undefined)
    const [chineseSettingValue, setChineseSettingValue] = useState()
    const [cambodianSettingValue, setCambodianSettingValue] = useState()
    const [englishSettingValue, setEnglishSettingValue] = useState()
    const [priceValue, setPriceValue] = useState()

    const onSubmitButtonClick = () => {
        const productInterface: ProductInterface = {
            id: productInfo.product.id,
            titleZh: chineseSettingValue["name"],
            titleKm: cambodianSettingValue["name"],
            titleEn: englishSettingValue["name"],
            descriptionZh: chineseSettingValue["description"],
            descriptionKm: cambodianSettingValue["description"],
            descriptionEn: englishSettingValue["description"],
            priceUsd: priceValue['priceUSD'],
            priceKhr: priceValue['priceKHR']
        }
        editProduct(productInterface).then(r => {console.log(r)})
    }

    const setDefaultValue = (r) => {
        console.log(r)
        if(r===undefined)return
        setChineseSettingValue({
            name: r.product.titleZh,
            description: r.product.descriptionZh
        })
        setCambodianSettingValue({
            name: r.product.titleKm,
            description: r.product.descriptionKm
        })
        setEnglishSettingValue({
            name: r.product.titleEn,
            description: r.product.descriptionEn
        })
        setPriceValue({
            priceUSD: r.product.priceUsd,
            priceKHR: r.product.priceKhr
        })
    }
  useEffect(() => {
    getProductId(params.id).then((r) => {
        setDefaultValue(r)
        setProductInfo(r)
        addProductTranslation(r.product)
    });
  }, [params.id]);
  return (
    <>
      <h1>{t("Product Edit")}: {productInfo&&t(productInfo.product.titleEn)}</h1>
        {productInfo&&productInfo.product&&
            <div>
                <GeneralPanel valueSetter={setChineseSettingValue} title={"Chinese"} required={true} initialValue={{
                    name: productInfo.product.titleZh,
                    description: productInfo.product.descriptionZh
                }}/>
                <GeneralPanel valueSetter={setCambodianSettingValue} title={"Cambodian"} required={false}
                              initialValue={{
                                  name: productInfo.product.titleKm,
                                  description: productInfo.product.descriptionKm
                              }}/>
                <GeneralPanel valueSetter={setEnglishSettingValue} title={"English"} required={false} initialValue={{
                    name: productInfo.product.titleEn,
                    description: productInfo.product.descriptionEn
                }}/>
                <PricePanel valueSetter={setPriceValue} title={"Price Setting"} initialValue={{
                    priceUSD: productInfo.product.priceUsd,
                    priceKHR: productInfo.product.priceKhr
                }}/>
                <Row justify={"space-between"} style={{paddingTop: 30}}>
                    <div></div>
                    <Button type={"primary"} onClick={onSubmitButtonClick}>提交</Button>
                </Row>
            </div>
        }

    </>
  );
};

export default Product;
