import { Card, Row, Typography } from "antd";
import dayjs from "dayjs";
const { Paragraph, Text } = Typography;
const { Meta } = Card;

const ArticleCard = ({ title, content, author, time }) => {
  const getTime = (time) => {
    //dayjs
    return dayjs(time).format("YYYY-MM-DD HH:mm:ss");
  };
  return (
    <Card
      hoverable
      style={{ height: 400, width: 410 }}
      cover={
        <img
          alt="example"
          src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
          height={210}
        />
      }
    >
      <Meta
        title={title}
        description={
          <>
            <Paragraph ellipsis={{ rows: 4 }}>{content}</Paragraph>
            <Paragraph>
              <Row justify={"space-between"}>
                <Text style={{ color: "#949494" }}>{author}</Text>
                <Text style={{ color: "#949494" }}>{getTime(time)}</Text>
              </Row>
            </Paragraph>
          </>
        }
      />
    </Card>
  );
};

export default ArticleCard;
