import { Content } from "antd/es/layout/layout";
import { RouterProvider } from "react-router-dom";
import router from "../../utils/router/RouterSettings";
import "./content.css";
const ContentImpl = () => {
  return (
    <Content className="content">
      <RouterProvider router={router} />
    </Content>
  );
};

export default ContentImpl;
