import { Tabs } from "antd";
import ProductAdmin from "./components/ProductAdmin";
import { useTranslation } from "react-i18next";

const tabItems = [
  {
    key: "product",
    label: "Product Management",
    children: <ProductAdmin />,
  },
];

const Admin = () => {
  const { t } = useTranslation();
  return (
    <Tabs
      defaultActiveKey="product"
      tabPosition="left"
      centered
      items={tabItems.map((item) => ({
        key: item.key,
        label: t(item.label),
        children: item.children,
      }))}
    />
  );
};

export default Admin;
