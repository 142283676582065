import { useTranslation } from "react-i18next";
import { Collapse, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";

const GeneralPanel = ({ valueSetter, title, required, initialValue }) => {
  const { t } = useTranslation();
  return (
    <Collapse
      items={[
        {
          key: title,
          label: t(title),
          children: (
            <GeneralPanelContent
              valueSetter={valueSetter}
              required={required}
              initialValue={initialValue}
            />
          ),
        },
      ]}
    />
  );
};

const GeneralPanelContent = ({ valueSetter, required, initialValue }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onValuesChange={() => {
        valueSetter(form.getFieldsValue());
      }}
    >
      <Form.Item
        name="name"
        label={t("Name")}
        rules={[{ required: required }]}
        initialValue={initialValue["name"]}
      >
        <Input placeholder={t("Please input name of the product")} />
      </Form.Item>
      <Form.Item
        name="description"
        label={t("Description")}
        rules={[{ required: required }]}
        initialValue={initialValue["description"]}
      >
        <TextArea placeholder={t("Please input description of the product")} />
      </Form.Item>
    </Form>
  );
};

export default GeneralPanel;
