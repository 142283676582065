import React from "react";
import BottomTab from "./components/BottomTab";
import ContentMobile from "./components/ContentMobile";
import "./MobileComponent.css";
import FloatButtonImpl from "../components/float_button";

const MobileApp = () => {
  return (
    <div className="app">
      <div className={"body"}>
        <ContentMobile />
      </div>
      <div className={"bottom"}></div>
      <FloatButtonImpl />
      <BottomTab />
    </div>
  );
};

export default MobileApp;
