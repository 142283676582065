import { useParams } from "react-router-dom";
import {useEffect, useState} from "react";
import {getProductId, ProductResponseInterface} from "../../../utils/axios/api/ProductInterface";
import { Swiper,Divider } from "antd-mobile";
import {Col, Row} from "antd";
import {ArticleInterface} from "../../../utils/axios/api/ArticleInterface";
import i18n from "../../../i18n/i18n";
import {ProductInterface} from "../../../utils/axios/api/ProductInterface";
import {useTranslation} from "react-i18next";


const addArticleTranslation = (product: ProductInterface) => {
  const translation = {
    zh: {
      translation: {}
    },
    en: {
      translation: {}
    },
    km: {
      translation: {}
    }
  };
  translation.zh.translation[product.titleEn] = product.titleZh;
  translation.en.translation[product.titleEn] = product.titleEn;
  translation.km.translation[product.titleEn] = product.titleKm;
  translation.zh.translation[product.titleEn+"description"] = product.descriptionZh;
  translation.en.translation[product.titleEn+"description"] = product.descriptionEn;
  translation.km.translation[product.titleEn+"description"] = product.descriptionKm;
  i18n.addResourceBundle('en', 'translation', translation.en.translation, true, true);
  i18n.addResourceBundle('zh', 'translation', translation.zh.translation, true, true);
  i18n.addResourceBundle('km', 'translation', translation.km.translation, true, true);
}


const MobileProductDetail = () => {
  const params = useParams();
  const [productDetail:ProductResponseInterface, setProductDetail] = useState(null);
  const {t} = useTranslation()
  useEffect(() => {
    if (params.id === undefined) return;
    getProductId(params.id).then((res) => {
      addArticleTranslation(res.product);
      setProductDetail(res)
    });
  }, [params.id]);
  return (
    <>
      {productDetail&&
          <div>
            <Swiper>
              {productDetail.imgUrlList.map((imgUrl, index) => {
                return <Swiper.Item key={index}><img src={imgUrl} alt={"img"}
                                                     style={{width: '100%', height: 250}}/></Swiper.Item>
              })}
            </Swiper>
            <Row justify='space-evenly'>
              <Col span={2}></Col>
              <Col span={6}>
                <Row>
                  <h4 style={{alignSelf: "end", color: "#ff3200"}}>KHR</h4>
                  <h1 style={{alignSelf: "end", color: "#ff3200"}}>{productDetail.product.priceKhr}</h1>
                </Row>
              </Col>
              <Col span={6}>
                <Row>
                  <h4 style={{alignSelf: "end", color: "#ff3200"}}>USD</h4>
                  <h1 style={{alignSelf: "end", color: "#ff3200"}}>{productDetail.product.priceUsd}</h1>
                </Row>
              </Col>
              <Col span={10}></Col>
            </Row>
            {/*<Divider style={{height:1}}/>*/}
            <h2 style={{paddingLeft: 30, paddingRight: 30}}>
              {t(productDetail.product.titleEn)}
            </h2>

            <h3 style={{paddingLeft: 30, paddingRight: 30}}>
                {t(productDetail.product.titleEn+'description')}

            </h3>


          </div>
      }
    </>
  );
};

export default MobileProductDetail;
