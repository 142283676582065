import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchArticleOfIndex} from "../../utils/axios/api/ArticleInterface";
import {ArticleIndexInterface, fetchArticleIndexById} from "../../utils/axios/api/ArticleIndexInterface";
import {ArticleInterface} from "../../utils/axios/api/ArticleInterface";
import {useTranslation} from "react-i18next";
import i18n from "../../i18n/i18n";
import {Descriptions, Row, Spin, Typography} from "antd";
import ArticleCard from "./components/ArticleCard";
const {Title} = Typography;

const {DescriptionsItem} = Descriptions;

const GetArticle = async (type) => {
    const article: ArticleInterface[] = await fetchArticleOfIndex(type)
    for (let i = 0; i < article.length; i++) {
      addArticleTranslation(article[i]);
    }
    return article;
};

const GetCurrentIndexInfo = async (type) => {
    const articleIndex = await fetchArticleIndexById(type)
    if (articleIndex === null) {
        return {}
    }
    return articleIndex;
};

const addArticleTranslation = (article: ArticleInterface) => {
    const translation = {
        zh: {
            translation: {}
        },
        en: {
            translation: {}
        },
        km: {
            translation: {}
        }
    };
    translation.zh.translation[article.titleEn] = article.titleZh;
    translation.en.translation[article.titleEn] = article.titleEn;
    translation.km.translation[article.titleEn] = article.titleKm;
    translation.zh.translation[article.titleEn+"+content"] = article.contentZh;
    translation.en.translation[article.titleEn+"+content"] = article.contentEn;
    translation.km.translation[article.titleEn+"+content"] = article.contentKm;
    i18n.addResourceBundle('en', 'translation', translation.en.translation, true, true);
    i18n.addResourceBundle('zh', 'translation', translation.zh.translation, true, true);
    i18n.addResourceBundle('km', 'translation', translation.km.translation, true, true);
    return article
}

const ArticleIndex = () => {
    const params = useParams();
    const [articles: ArticleInterface[], setArticles] = useState([]);
    const [indexInfo: ArticleIndexInterface, setIndexInfo] = useState({})
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation();

    useEffect(() => {
        const loadArticle = async () => {
            setLoading(true)
            setArticles(await GetArticle(params.id))
            setIndexInfo(await GetCurrentIndexInfo(params.id))
            return "done"
        };
        loadArticle().finally(()=>setLoading(false))

    }, [params.id]);
    return (
        <Spin size="large" spinning={loading}>
            <Row justify={'center'}>
                <Title>{t(indexInfo.titleEn)}</Title>
            </Row>
            <Descriptions column={{ xxl:4, xl:3, lg:2,md:2, sm :1,xs:1, }} layout={"vertical"}>
                {articles.map((article, index) => {
                    return (
                        <DescriptionsItem>
                            <ArticleCard title={t(article.titleEn)} content={t(article.titleEn+"+content")} author={article.author} time={article.timeCreated}/>
                        </DescriptionsItem>
                    );
                })}
            </Descriptions>
        </Spin>
    );
};

export default ArticleIndex;
