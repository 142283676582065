import { Button, Row } from "antd";
import { useTranslation } from "react-i18next";

const HeaderButton = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="button-row">
        <Button type={"primary"}>{t("Search")}</Button>
        <Button>{t("ShoppingCart")}</Button>
      </Row>
    </>
  );
};

export default HeaderButton;
