import { useEffect, useState } from "react";
import {getProductList, ProductResponseInterface} from "../../../utils/axios/api/ProductInterface";
import {useTranslation} from "react-i18next";
import {Button, Row, Table} from "antd";
import i18n from "../../../i18n/i18n";
import {ProductInterface} from "../../../utils/axios/api/ProductInterface";
import Search from "antd/es/input/Search";
import router from "../../../utils/router/RouterSettings";


const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width:"20%"
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        ellipsis:true,
        width:"50%"
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        width:"10%"
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width:"10%"
    },
];

export const addProductTranslation = (product: ProductInterface) => {
    const translation = {
        zh: {
            translation: {}
        },
        en: {
            translation: {}
        },
        km: {
            translation: {}
        }
    };
    translation.zh.translation[product.titleEn] = product.titleZh;
    translation.en.translation[product.titleEn] = product.titleEn;
    translation.km.translation[product.titleEn] = product.titleKm;
    translation.zh.translation[product.titleEn+"+description"] = product.descriptionZh;
    translation.en.translation[product.titleEn+"+description"] = product.descriptionEn;
    translation.km.translation[product.titleEn+"+description"] = product.descriptionKm;
    i18n.addResourceBundle('en', 'translation', translation.en.translation, true, true);
    i18n.addResourceBundle('zh', 'translation', translation.zh.translation, true, true);
    i18n.addResourceBundle('km', 'translation', translation.km.translation, true, true);
}

const proceedProductList = (productList: ProductResponseInterface[]) => {
    productList.forEach(product => {
        addProductTranslation(product.product);
    })
}

const onEditButtonPushed = (id)=>{
    router.navigate(`/admin/product/edit/${id}`).then((r) => {});
}

const ProductAdmin = () => {
  const [productList:ProductResponseInterface[], setProductList] = useState([]);
  const [showingProduct:ProductResponseInterface[], setShowingProduct] = useState([])
    const [filerWord, setFilerWord] = useState("")
  const {t} = useTranslation()
  useEffect(() => {
    getProductList().then((res) => {
      setProductList(res);
      setShowingProduct(res)
        setFilerWord("")
      proceedProductList(res)
    });
  }, []);
    useEffect(() => {
        setShowingProduct(productList.filter(product => {
            return t(product.product.titleEn).includes(filerWord)
        }))
    }, [filerWord,productList]);
  return (
      <>
          <Row style={{alignItems:'center'}} justify={"space-between"}>
              <h1>{t("Product Management")}</h1>
              <Search placeholder="搜索" onSearch={setFilerWord} style={{width: 200}}/>
          </Row>

          <Table columns={columns.map(column => {
              const newColumn = {...column}
              newColumn.title = t(column.title)
              return newColumn
          })} dataSource={showingProduct.map(product=>{
              console.log(t(product.product.titleEn))
              return{
                  key: product.product.id,
                  name: t(product.product.titleEn),
                  description: t(product.product.titleEn+"+description"),
                  price: product.product.priceUsd,
                  action: <Button onClick={()=>onEditButtonPushed(product.product.id)}>{t("Edit")}</Button>
              }
          })}>
          </Table>

      </>
  );
};

export default ProductAdmin;
