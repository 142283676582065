import { get, post, postWithMessage } from "../Request";
import { translate } from "../../../i18n/translate";
export interface ProductInterface {
  id: number;
  titleZh: string;
  titleEn?: string;
  titleKm?: string;
  descriptionZh: string;
  descriptionEn?: string;
  descriptionKm?: string;
  priceKhr: number;
  priceUsd: number;
}

export interface ProductImgInterface {
  id: number;
  filename: string;
  productId: number;
}

export interface ProductResponseInterface {
  product: ProductInterface;
  imgUrlList: string[];
}

export interface UploadProductImageUrlResponseInterface {
  url: string;
  keyName: string;
}

export async function editProduct(product: ProductInterface) {
  try {
    const response: ProductResponseInterface = await postWithMessage(
      "/admin/editProduct",
      product,
      translate("Updating product data..."),
      translate("Product data updated successfully."),
      translate("Failed to update product data."),
    );
    if (response) {
      return response;
    } else {
      console.error("No data returned from editProduct.");
      return null;
    }
  } catch (error) {
    console.error("Error updating the product data:", error);
    return null;
  }
}

export async function getProductId(id: number) {
  try {
    const response: ProductResponseInterface = await get("/product/get", {
      id: id,
    });
    if (response) {
      return response;
    } else {
      console.error("No data returned from getProductList.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching product list:", error);
    return null;
  }
}

export async function getProductList() {
  try {
    const response: ProductResponseInterface[] = await get("/product/list", {});
    if (response) {
      return response;
    } else {
      console.error("No data returned from getProductList.");
      return [];
    }
  } catch (error) {
    console.error("Error fetching product list:", error);
    return [];
  }
}

export async function getUploadProductImageUrl(filename: string) {
  const response: UploadProductImageUrlResponseInterface = await get(
    "/admin/getUploadProductImageUrl",
    {
      filename,
    },
  );
  if (response) {
    return response;
  } else {
    throw new Error("No data returned from getUploadProductImageUrl.");
  }
}

export async function addProductImageInfo(keyName: string) {
  try {
    await post("/admin/addProductImageInfo", {
      keyName,
    });
  } catch (error) {
    console.error("Error adding product image info:", error);
    return false;
  }
}
