import HeaderImpl from "./components/header/HeaderImpl";
import { ConfigProvider, Layout } from "antd";
import ContentImpl from "./components/content/ContentImpl";
import "./App.css";
import { DefaultThemeConfig } from "./config/GlobalThemeConfig";
import FooterImpl from "./components/footer/FooterImpl";
import FloatButtonImpl from "./components/float_button";
import { useEffect, useState } from "react";
import MobileApp from "./mobile/MobileApp";
import router from "./utils/router/RouterSettings";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobile(true);
      if (!router.state.location.pathname.startsWith("/mobile"))
        router.navigate("/mobile").then((r) => {});
    }
  }, []);

  return (
    <>
      {!isMobile ? (
        <ConfigProvider theme={DefaultThemeConfig}>
          <Layout className="container">
            <HeaderImpl />
            <ContentImpl />
            <FooterImpl />
            <FloatButtonImpl />
          </Layout>
        </ConfigProvider>
      ) : (
        <MobileApp />
      )}
    </>
  );
}

export default App;
