import { Badge, TabBar } from "antd-mobile";
import React, { useState } from "react";
import {
  AppOutline,
  AppstoreOutline,
  MessageFill,
  MessageOutline,
  UserOutline,
} from "antd-mobile-icons";
import router from "../../utils/router/RouterSettings";

const BottomTab = () => {
  const tabs = [
    {
      key: "/mobile/index",
      title: "首页",
      icon: <AppOutline />,
    },
    {
      key: "/mobile/product",
      title: "产品",
      icon: <AppstoreOutline />,
    },
  ];

  const onChange = (key) => {
    setActiveKey(key);
    router.navigate(key).then((res) => {});
  };

  const [activeKey, setActiveKey] = useState("");
  return (
    <TabBar activeKey={activeKey} onChange={onChange}>
      {tabs.map((item) => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  );
};

export default BottomTab;
