import { useTranslation } from "react-i18next";
import { Collapse, Form, Input, InputNumber } from "antd";
import TextArea from "antd/es/input/TextArea";

const PricePanel = ({ valueSetter, title, initialValue }) => {
  const { t } = useTranslation();
  return (
    <Collapse
      items={[
        {
          key: title,
          label: t(title),
          children: (
            <PricePanelContent
              valueSetter={valueSetter}
              initialValue={initialValue}
            />
          ),
        },
      ]}
    />
  );
};

const PricePanelContent = ({ valueSetter, initialValue }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onValuesChange={() => {
        valueSetter(form.getFieldsValue());
      }}
    >
      <Form.Item
        name="priceUSD"
        label={t("USD")}
        rules={[{ required: true }]}
        initialValue={initialValue["priceUSD"]}
      >
        <InputNumber
          placeholder={t("Please input the price of the product in USD")}
          min="0"
          step="0.01"
        />
      </Form.Item>
      <Form.Item
        name="priceKHR"
        label={t("KHR")}
        rules={[{ required: true }]}
        initialValue={initialValue["priceKHR"]}
      >
        <InputNumber
          placeholder={t("Please input the price of the product in KHR")}
          min="0"
          step="0.01"
        />
      </Form.Item>
    </Form>
  );
};

export default PricePanel;
