import { get } from "../Request";
import { ArticleIndexInterface } from "./ArticleIndexInterface";

export interface ArticleInterface {
  id: number;
  titleZh: string;
  titleEn?: string; // 可选属性，因为@Column注解没有标记为nullable=false
  titleKm?: string; // 可选属性
  author: string;
  timeCreated: Date;
  timeUpdated?: Date; // 可选属性
  contentZh: string;
  type: number;
  contentEn?: string; // 可选属性
  contentKm?: string; // 可选属性
}

export const fetchArticleOfIndex = async (index: number) => {
  try {
    const response: ArticleInterface[] = await get("/article/getByType", {
      type: index,
    });
    if (response) {
      return response;
    } else {
      console.error("No data returned from fetchArticleIndex.");
      return [];
    }
  } catch (error) {
    console.error("Error fetching article index:", error);
    return [];
  }
};
