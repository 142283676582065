import { Header } from "antd/es/layout/layout";
import HeaderMenu from "./HeaderMenu";
import { Row } from "antd";
import HeaderLogo from "./HeaderLogo";
import HeaderButton from "./HeaderButton";
import "/node_modules/flag-icons/css/flag-icons.min.css";
const HeaderImpl = () => {
  return (
    <Header>
      <Row justify={"space-between"}>
        <HeaderLogo />
        <HeaderMenu />
        <HeaderButton />
      </Row>
    </Header>
  );
};

export default HeaderImpl;
