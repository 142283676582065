import { createBrowserRouter } from "react-router-dom";
import Index from "../../pages/index";
import ArticleIndex from "../../pages/article_index";
import Admin from "../../pages/admin";
import Product from "../../pages/admin/pages/product/edit";
import MobileProduct from "../../mobile/pages/product";
import MobileProductDetail from "../../mobile/pages/product_detail";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },
  {
    path: "/index",
    element: <Index />,
  },
  { path: "/admin", element: <Admin /> },
  { path: "/article_index/:id", element: <ArticleIndex /> },
  { path: "/admin/product/edit/:id", element: <Product /> },
  { path: "/mobile/product", element: <MobileProduct /> },
  { path: "/mobile/product/:id", element: <MobileProductDetail /> },
  { path: "/mobile/index", element: <div>index</div> },
  { path: "/mobile", element: <div>index</div> },
]);

export default router;
