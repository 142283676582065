import { useEffect, useState } from "react";
import {getProductList, ProductResponseInterface} from "../../../utils/axios/api/ProductInterface";
import {Button} from "antd";
import router from "../../../utils/router/RouterSettings";

const MobileProduct = () => {
  const [productList:ProductResponseInterface[], setProductList] = useState([]);

  useEffect(() => {
    getProductList().then((res) => {
      setProductList(res);
    });
  }, []);
  return (
    <div>
        test
        {productList.map((product,index)=>{
            return (
                // <div key={index}>{product.product.titleZh}</div>
                <Button onClick={()=>{router.navigate(`/mobile/product/${product.product.id}`)}}
                        key={index}>{product.product.titleZh}
                </Button>
            )
        })}
    </div>
  );
};

export default MobileProduct;
