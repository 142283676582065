import { ThemeConfig } from "antd";

const DefaultThemeConfig: ThemeConfig = {
  token: {
    fontFamily: "HarmonyOSSans",
  },
  components: {
    Layout: {
      headerBg: "#f3f3f6",
    },
    Button: {
      borderRadius: 15,
      colorPrimary: "#184eff",
    },
    FloatButton: {
      fontSizeIcon: 18,
    },
    Card: {
      borderRadius: 15,
      colorBgContainer: "#f3f3f6",
      colorBorder: "#000000",
      colorBorderBg: "#000000",
    },
  },
};

export { DefaultThemeConfig };
