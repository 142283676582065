import { get } from "../Request";

interface ArticleIndexInterface {
  id: number;
  titleZh: string;
  titleEn: string;
  titleKm: string;
}

const fetchArticleIndex = async () => {
  try {
    // 调用封装好的get函数，传入URL和参数（如果有的话）
    const response: ArticleIndexInterface[] = await get(
      "/article_index/getAll",
      {},
    );
    // 假设后端返回的数据格式是{ data: ArticleIndex[] }
    if (response) {
      return response;
    } else {
      console.error("No data returned from fetchArticleIndex.");
      return [];
    }
  } catch (error) {
    console.error("Error fetching article index:", error);
    return [];
  }
};

const fetchArticleIndexById = async (index: number) => {
  try {
    const response: ArticleIndexInterface = await get("/article_index/get", {
      id: index,
    });
    if (response) {
      return response;
    } else {
      console.error("No data returned from fetchArticleIndexById.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching article index by id:", error);
    return null;
  }
};

export { fetchArticleIndex, fetchArticleIndexById, ArticleIndexInterface };
