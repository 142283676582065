import "./Header.css";
const HeaderLogo = () => {
  return (
    <img
      src={require("../../assets/logo_word.png")}
      alt="logo"
      className={"logo"}
    />
  );
};

export default HeaderLogo;
