import { FloatButton } from "antd";
import { TranslationOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const FloatButtonImpl = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang).then((r) => {});
  };

  return (
    <FloatButton.Group
      trigger="click"
      type="primary"
      style={{ right: 24 }}
      icon={<TranslationOutlined size={18} />}
      shape={"square"}
    >
      <FloatButton
        icon={<span className="fi fi-cn fis"></span>}
        onClick={() => changeLanguage("zh")}
      />
      <FloatButton
        icon={<span className="fi fi-kh fis"></span>}
        onClick={() => changeLanguage("km")}
      />
      <FloatButton
        icon={<span className="fi fi-gb fis"></span>}
        onClick={() => changeLanguage("en")}
      />
    </FloatButton.Group>
  );
};

export default FloatButtonImpl;
