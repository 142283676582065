import { Menu } from "antd";
import { useEffect, useState } from "react";
import router from "../../utils/router/RouterSettings";
import { useTranslation } from "react-i18next";

import "./Header.css";
import { fetchArticleIndex } from "../../utils/axios/api/ArticleIndexInterface";
import {ArticleIndexInterface} from "../../utils/axios/api/ArticleIndexInterface";
import i18n from "../../i18n/i18n";
const { SubMenu,Item } = Menu;

/**
 * Fetches the article index and generates a menu based on the fetched data.
 * @returns {Promise<Array>} A promise that resolves to an array of menu items.
 */
const getArticleMenu = async () => {
  const responseData: ArticleIndexInterface[] = await fetchArticleIndex();
  addArticleIndexTranslation(responseData)
  const articleMenu = [];
  for (let i = 0; i < responseData.length; i++) {
    articleMenu.push({
        key: "/article_index/" + responseData[i].id,
        label: responseData[i].titleEn,
        });
  }
  return articleMenu;
};

/**
 * Adds translations for the article index to the i18n resource bundle.
 * @param {Array} responseData - The article index data.
 */
const addArticleIndexTranslation = (responseData: ArticleIndexInterface[])=>{
  const translation = {
    zh: {
      translation: {
      }
    },
    en: {
      translation: {
      }
    },
    km: {
      translation: {
      }
    }
  };
  for(let i = 0; i < responseData.length; i++) {
    translation.zh.translation[responseData[i].titleEn] = responseData[i].titleZh;
    translation.en.translation[responseData[i].titleEn] = responseData[i].titleEn;
    translation.km.translation[responseData[i].titleEn] = responseData[i].titleKm;
  }
  i18n.addResourceBundle('en', 'translation', translation.en.translation, true, true);
  i18n.addResourceBundle('zh', 'translation', translation.zh.translation, true, true);
  i18n.addResourceBundle('km', 'translation', translation.km.translation, true, true);
}

/**
 * HeaderMenu component.
 * @component
 */
const HeaderMenu = () => {
  const [currentKey, setCurrentKey] = useState("index");
  const { t } = useTranslation();
  const [articleIndexes, setArticleIndexes] = useState([])
  useEffect(() => {
    getArticleMenu().then((r) => {
      setArticleIndexes(r);
    });
  }, []);
  const onClick = (e) => {
    setCurrentKey(e.key);
    router.navigate(e.key).then((r) => {});
  };
  return (
    <Menu
      onClick={onClick}
      selectedKeys={[currentKey]}
      mode="horizontal"
      style={{ minWidth: 300 }}
      className={"menu"}
    >
      <Item key="/index">{t("Index")}</Item>
        <SubMenu key="article" title={t("Articles")}>
            {articleIndexes.map((item) => (
            <Item key={item.key}>{t(item.label)}</Item>
            ))}
        </SubMenu>
        <Item key="/product">{t("Products")}</Item>
    </Menu>

  );
};

export default HeaderMenu;