import router from "../../utils/router/RouterSettings";
import { RouterProvider } from "react-router-dom";

import "../MobileComponent.css";

const ContentMobile = () => {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default ContentMobile;
